
import { Drawer } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import * as XLSX from 'xlsx/xlsx.mjs';
const { Dragger } = Upload;



export default function MyRightDrawer (props){

  //

  

  const onClose = () => {
    props.setOpen(false);
  };


  const props1 = {
    name: 'file',
    multiple: false,
    beforeUpload:async  (file) => {
      const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isXLSX) {
        message.error(`${file.name} is not a xlsx file`);
        return false;
      }
    
      const fileReader = new FileReader();
    
      return new Promise((resolve, reject) => {
        fileReader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const range = XLSX.utils.decode_range(sheet['!ref']);
          const rowCount = range.e.r + 1; // 计算行数
    
          if (rowCount > 1000) {
            message.error(`文件 ${file.name} 行数 ${rowCount}  超过 最大限制  , 上传失败.`);
            resolve(false);
          } else {
            resolve(true);
          }
        };
    
        fileReader.onerror = (error) => {
          reject(error);
        };
    
        fileReader.readAsArrayBuffer(file);
      });
    },

    action: 'https://www.skinmira.fr/api/upload/uploadorderdewuTrackingNo',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        message.info(`${info.file.response} `);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      //console.log('Dropped files', e.dataTransfer.files);
    },

  };


    return(
      <Drawer title="上传得物运单号" placement="right" onClose={onClose} open={props.open}>
      {//window.sessionStorage.printer?listprinter.length>0?listprinter[window.sessionStorage.printer].label:'无' :'无'
      }
        
  <Dragger {...props1}>
  <p className="ant-upload-text">上传得物运单号</p>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">点击或拖拽文件至此区域即可上传</p>
  </Dragger>
    </Drawer>
    )
}